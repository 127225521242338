import { createContext, useContext, useMemo, ReactNode } from "react";
import { WebsiteType } from "../../helper/enums/website_types";

// Define the context type
export type WebsiteTypeContextType = {
  websiteType?: WebsiteType | undefined;
};

// Create context with undefined as the default value
export const WebsiteTypeContext = createContext<WebsiteTypeContextType | undefined>(undefined);

// WebsiteTypeProvider component
export default function WebsiteTypeProvider({ children }: { children: ReactNode }) {
  // Memoize the website type from environment variable
  const websiteType = useMemo(() => process.env.NEXT_PUBLIC_WEBSITE_TYPE as WebsiteType || undefined, []);

  // Provide the website type value to the context
  const providerValue = useMemo(() => ({ websiteType }), [websiteType]);

  return <WebsiteTypeContext.Provider value={providerValue}>{children}</WebsiteTypeContext.Provider>;
}

export const useIsBoiWebsite = (): boolean => {
  const context = useContext(WebsiteTypeContext);
  if (!context) {
    throw new Error("useIsBoiWebsite must be used within a WebsiteTypeProvider");
  }
  return context.websiteType === WebsiteType.BANK_OF_IRELAND;
};