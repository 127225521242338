import Backend from "./Backend";
import { getUserData } from "./UserManager";
import analyticsActions from "../helper/analytics_actions";
import { deleteSession, isSession } from "./SessionManager";
import { useIsBoiWebsite } from "../components/Context/WebsiteType";
import { useEffect } from "react";

export function useStartSessionEvent(county: string, country: string, campaign_id?: string) {
  const isBoiWebsite = useIsBoiWebsite();

  useEffect(() => {
    startSessionEvent(county, country, campaign_id, isBoiWebsite);
  }, [county, country, campaign_id, isBoiWebsite]);
}

export function startSessionEvent(county: string, country: string, campaign_id?: string, isBoiWebsite: boolean = false) {

  if (window.QAMode) {
    console.log('Start session:', county, country, campaign_id)
    return;
  }

  if (!isSession()) { // if session hasn't started already
    const { uuid, session_id } = getUserData(true);
    
    if (session_id && uuid) {
      Backend.analyticsEvent({
        uuid, session_id,
        action: isBoiWebsite ? analyticsActions.session_started_boi : analyticsActions.session_started,
        data: JSON.stringify({ county, country, campaign_id })
      })
      ?.catch(() => {
        console.log("Error while initializing session")
        deleteSession()
      })
    }
  }
}

// vehicles shoudld be in format { vehicle_id_1: 1, vehicle_id_2: 2 } up to 4 ids
export function vehicleCompareEvent(isBoiSite: boolean, vehicles: number[]) {
  if (window.QAMode) {
    console.log('Vehicle compare event')
    return;
  }

  const { uuid, session_id } = getUserData();

  if (session_id && uuid) {
    Backend.analyticsEvent({
      uuid, session_id,
      action: isBoiSite ? analyticsActions.vehicle_compare_boi : analyticsActions.vehicle_compare,
      data: JSON.stringify({ 
        vehicle_id_1: vehicles[0],
        vehicle_id_2: vehicles[1],
        vehicle_id_3: vehicles[2],
        vehicle_id_4: vehicles[3]
      })
    })
    ?.catch(() => {
      console.log("Error while comparing vehicles event")
    })
  }
}

// lists of makes and models ids
export function vehicleSearchEvent(isBoiSite: boolean, make: (number|string)[], model: (number|string)[]) {
  if (window.QAMode) {
    console.log('vehicle search (home page)')
    return;
  }
  
  const { uuid, session_id } = getUserData();
  
  if (session_id && uuid) {
    Backend.analyticsEvent({
      uuid, session_id,
      action: isBoiSite ? analyticsActions.vehicle_search_boi : analyticsActions.vehicle_search,
      data: JSON.stringify({ make, model })
    })
    ?.catch(() => {
      console.log("Error while searching vehicles event")
    })
  }
}

// parse filters data ||
export function vehicleFilterEvent(isBoiSite: boolean, filter: any) {
  if (window.QAMode) {
    console.log('vehicle filter event')
    return;
  }
  
  const { uuid, session_id } = getUserData();

  if (session_id && uuid) {
    // formatting fields to match backend
    const formattedFilters = { ...filter }

    formattedFilters.make = formattedFilters.makes.map((make: any) => make.value)
    formattedFilters.model = formattedFilters.models.map((model: any) => model.value)
    delete formattedFilters.order_by
    delete formattedFilters.makes
    delete formattedFilters.models

    const parseValue = (fields: string[]) => {
      fields.forEach(field => {
        formattedFilters[field] = formattedFilters[field]?.value 
      })
    }
    parseValue(['price_min', 'price_max', 'range_min', 'range_max', 'body_type', 'fuel_type'])

    Backend.analyticsEvent({
      uuid, session_id,
      action: isBoiSite ? analyticsActions.vehicle_filter_boi : analyticsActions.vehicle_filter,
      data: JSON.stringify({ ...formattedFilters })
    })
    ?.catch(() => {
      console.log("Error while filtering vehicles event")
    })
  }
}

export function featuredVehicleEvent(isBoiSite: boolean, vehicle_id: number) {
  if (window.QAMode) {
    console.log('Featured vehicle click:', vehicle_id)
    return;
  }
  
  const { uuid, session_id } = getUserData();
  
  if (session_id && uuid) {
    Backend.analyticsEvent({
      uuid, session_id,
      action: isBoiSite ? analyticsActions.vehicle_featured_boi_click : analyticsActions.vehicle_featured_click,
      data: JSON.stringify({ vehicle_id })
    })
    ?.catch(() => {
      console.log("Error while featured vehicle click event")
    })
  }
}

export function adEvent(ad_id: number, name: string, type: string, action: string) {
  if (window.QAMode) {
    console.log('Ad Event:', ad_id, name, type, action)
    return;
  }
  
  const { uuid, session_id } = getUserData();

  if (session_id && uuid) {
    Backend.analyticsEvent({
      uuid, session_id,
      action: analyticsActions.ad_event,
      data: JSON.stringify({ 
        ad_id, name, type, action
      })
    })
    ?.catch(() => {
      console.log("Error while featured vehicle click event")
    })
  }
}

export function callDealershipEvent(isBoiSite: boolean, vehicle_id: number, dealership_id: number, used_ev = false) {
  if (window.QAMode) {
    console.log('Call dealership:', vehicle_id, dealership_id, 'Used ev:', used_ev)
    return;
  }
  
  const { uuid, session_id } = getUserData();
  
  if (session_id && uuid) {
    Backend.analyticsEvent({
      uuid, session_id,
      action: isBoiSite ? analyticsActions.vehicle_call_dealership_boi : analyticsActions.vehicle_call_dealership,
      data: JSON.stringify({ vehicle_id, dealership_id, used_ev })
    })
    ?.catch(() => {
      console.log("Error while call a dealership event")
    })
  }
}

/**
 * @description send video view event to backend, if video is watched, updates the analytic object in database
 * @param video_id - if of the video
 * @param watched - video considred to be watched if user watched 90% of it
 * @param analytics_id - id of the video in analytics system, used for updating the fact that user actually watched video
 * @returns if not watched, returns the analytics_id in order to update view status later
 */
export async function videoViewEvent(isBoiSite: boolean, video_id: number, watched = false, analytics_id?: string) {
  if (window.QAMode) {
    console.log(`Video view: ${video_id}; Watched: ${watched}`)
    return;
  }

  const { uuid, session_id } = getUserData();
  if (!session_id || !uuid) return

  try {
    const response = await Backend.analyticsEvent({
      uuid, session_id,
      action: isBoiSite ? analyticsActions.video_view_boi : analyticsActions.video_view,
      data: JSON.stringify({ video_id, watched })
    }, analytics_id)

    // if not watched, we should return analytics id for future
    if (!watched) {
      const new_analytics_id = response.data.content.id
      return new_analytics_id
    }
  }
  catch(err) {
    console.error("Analytics Error: video view event")
  }
}