import { v4 } from "uuid";

import Backend from "./Backend";
import { startSessionEvent } from "./Analytics";
import getSession, { deleteSession, startSession } from "./SessionManager";
import getLocation from "../lib/geocoder";
import { useIsBoiWebsite } from "../components/Context/WebsiteType";

// register new user or update existing user
export default async function manageUser(campaign_id?: string) {
  if (window.QAMode) return; // if QA is enabled we shouldn't track any analytics

  let county: string = 'Unknown';
  let country: string = 'Unknown';

  let location: any;
  
  try {
    location = await getLocation();
  } catch(error) {
    console.log("Failed to retrive user's location")
  }

  if (location) {
    county = location.county;
    country = location.country;
  }
  
  if (!!!localStorage.getItem("uuid")) { // user doens't exist
    const uuid = generateUUID();
    const device = getDeviceType();
    const browser = getBrowser();

    Backend.registerUser({uuid, device, browser, county, country})
    ?.then(() => {
      localStorage.setItem("uuid", uuid);

      startSessionEvent(county, country, campaign_id);
    })
    .catch(() => {
      console.error("Failed to register new user")
    })
  }
  else { // as user exists, we only need to start session
    startSessionEvent(county, country, campaign_id)
  }
}

// removing uuid from localStorage when user forbids to track statistics in CookieBot
export const deleteUser = () => {
  localStorage.removeItem("uuid")
  deleteSession()
}

// generates a unique id for the user and stores it in localStorage
const generateUUID = () => { 
  return v4();
}

export const getUUID = () => {
  if (!Cookiebot.consent.statistics) return null

  return localStorage.getItem("uuid")
}

export const getUserData = (initiateSession = false) => {
  return {
    uuid: getUUID(),
    session_id: initiateSession ? startSession() : getSession(),
  }
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "Tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "Mobile";
  }
  return "Desktop";
};

const getBrowser = () =>{
  let browserName = 'Unknown';

  const ua = navigator.userAgent;
          
  if (ua.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  }
  else if (ua.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  }  
  else if(ua.match(/safari/i)) {
    browserName = "Safari";
  }
  else if(ua.match(/opr\//i)) {
    browserName = "Opera";
  } 
  else if(ua.match(/edg/i)) {
    browserName = "Edge";
  }

  return browserName
}