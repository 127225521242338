import links from "./links";
import vehicleTypes, { vehicleSearchOptions } from "./vehicle_types";
import googleAdIds from "./google_ad_ids";
import bodyTypes from "./body_types";
import video_view from "./video";
import IGenericOption from "../../interfaces/IGenericOption";

export const libraries: any = ["places", "google-map-script"];

export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneRegex = /^(\+?)(\d{9,16})$/;

export const vehicleRegistationRegex = /^\d{1,3}(\s)?(KK|kk|ww|WW|c|C|ce|CE|cn|CN|cw|CW|d|D|dl|DL|g|G|ke|KE|ky|KY|l|L|ld|LD|lh|LH|lk|LK|lm|LM|ls|LS|mh|MH|mn|MN|mo|MO|oy|OY|so|SO|rn|RN|t|T|tn|TN|ts|TS|w|W|wd|WD|wh|WH|wx|WX)(\s)?\d{1,6}$/

export const imaginStudioURL = "https://cdn.imagin.studio"

// possible angles: 
// "01", "05", "09", "13",
// "17", "21", "22", "23", 
// "25", "27", "28", "29",
// "51"

export const imageAngles = [
  "01", "05", "09", "13",
  "17", "22", "28", "29",
]

export const commercialVehicles = ["Off-Road Commercial", "Commercial Hatch", "Car Van", "Panel Van", "Chassis Cab"]

export const sweepResourses = {
  locations: "locations",
  fuels: "fuels",
  bodies: "bodies",
  colors: "colors"
}

export const userRequestOptions: IGenericOption[] = [
  { value: "SCHEDULE_TEST_DRIVE", label: "Schedule a Test Drive" },
  { value: "REQUEST_CALLBACK", label: "Request a Callback" },
  { value: "CALL_DEALERSHIP", label: "Call a Dealership" },
  { value: "QUESTION", label: "Ask a Question" },
];

export const userRequestOptionsForBOI: IGenericOption[] = [
  { value: "SCHEDULE_TEST_DRIVE_BOI", label: "Schedule a Test Drive" },
  { value: "REQUEST_CALLBACK_BOI", label: "Request a Callback" },
  { value: "CALL_DEALERSHIP_BOI", label: "Call a Dealership" },
  { value: "QUESTION_BOI", label: "Ask a Question" },
];

export const financeRequestOptions: IGenericOption[] = [
  { value: "REQUEST_CALLBACK", label: "Request a Callback" },
  { value: "SCHEDULE_TEST_DRIVE", label: "Schedule a Test Drive" },
];

export { links, vehicleTypes, vehicleSearchOptions, bodyTypes, googleAdIds, video_view }