import iFilters from "../interfaces/iFilters";
import IOption from "../interfaces/IOption";

// Lists of availaible filters options:
export const sortOptions: IOption[] = [
	{ value: "", label: "Most Recent" },
	// { value: "most-viewed", label: "Most Viewed" },
	{ value: "a-z", label: "Alphabetical" },
	{ value: "price-low-high", label: "Price: Low to High" },
	{ value: "price-high-low", label: "Price: High to Low" },
	{ value: "range-low-high", label: "Range: Low to High" },
	{ value: "range-high-low", label: "Range: High to Low" },
	{ value: "acceleration", label: "Acceleration" }
];

export const fuelTypeOptions: IOption[] = [
	{ value: "", label: "Both (BEV & PHEV)" },
	{ value: "BEV", label: "All-Electric (BEV)" },
	{ value: "PHEV", label: "Plug-in Hybrid (PHEV)" }
];

export const privateBodies: IOption[] = [
	{ value: "SALOON", label: "Saloon" },
	{ value: "ESTATE", label: "Estate" },
	{ value: "HATCHBACK", label: "Hatchback" },
	{ value: "COUPE", label: "Coupé" },
	{ value: "CABRIOLET", label: "Cabriolet" },
	{ value: "MPV", label: "Multi-Purpose" },
	{ value: "CROSSOVER", label: "Crossover" },
	{ value: "SPORTS UTILITY VEHICLE", label: "Sports Utility Vehicle" },
	{ value: "SALOON HARD TOP", label: "Saloon (Hard Top)" },
	{ value: "COMBI", label: "Combi Coupé" },
	{ value: "MICRO CAR", label: "Microcar" },
	{ value: "MINI MPV", label: "Mini MPV" }
];
export const commercialBodies: IOption[] = [
	{ value: "PANEL VAN", label: "Panel Van" },
	{ value: "BUS", label: "Bus" },
	{ value: "OFF-ROAD COMMERCIAL", label: "Off-Road Commercial" },
	{ value: "CAR VAN", label: "Car Van" },
	{ value: "CHASSIS CAB", label: "Chassis Cab" },
	{ value: "COMMERCIAL HATCH", label: "Commercial Hatch" },
	{ value: "PLATFORM CAB", label: "Platform Cab" }
];

export const bodyTypes: IOption[] = [{ value: "all", label: "Any" }, { value: "PICK UP", label: "Pick Up" }, ...privateBodies];

export const bodyTypesCommercial: IOption[] = [{ value: "all", label: "Any" }, { value: "PICK UP", label: "Pick Up" }, ...commercialBodies];

const priceRange: IOption[] = [
	{ value: 10000, label: "€10,000" },
	{ value: 15000, label: "€15,000" },
	{ value: 20000, label: "€20,000" },
	{ value: 25000, label: "€25,000" },
	{ value: 30000, label: "€30,000" },
	{ value: 35000, label: "€35,000" },
	{ value: 40000, label: "€40,000" },
	{ value: 45000, label: "€45,000" },
	{ value: 50000, label: "€50,000" },
	{ value: 55000, label: "€55,000" },
	{ value: 60000, label: "€60,000" },
	{ value: 70000, label: "€70,000" },
	{ value: 80000, label: "€80,000" },
	{ value: 90000, label: "€90,000" },
	{ value: 100000, label: "€100,000" },
	{ value: 110000, label: "€110,000" },
	{ value: 120000, label: "€120,000" },
	{ value: 240000, label: "€240,000" }
];
export const minPriceRange: IOption[] = [{ value: "", label: "€0" }, ...priceRange];
export const maxPriceRange: IOption[] = [...priceRange, { value: "", label: "Max" }];

const batteryRange: IOption[] = [
	{ value: 200, label: "200 km" },
	{ value: 250, label: "250 km" },
	{ value: 300, label: "300 km" },
	{ value: 350, label: "350 km" },
	{ value: 400, label: "400 km" },
	{ value: 450, label: "450 km" },
	{ value: 500, label: "500 km" },
	{ value: 550, label: "550 km" },
	{ value: 600, label: "600 km" }
];
export const minBatteryRange: IOption[] = [{ value: "", label: "0 km" }, ...batteryRange];
export const maxBatteryRange: IOption[] = [...batteryRange, { value: "", label: "Max" }];

const cargoWeightRange: IOption[] = [
	{ value: 250, label: "250kg" },
	{ value: 500, label: "500kg" },
	{ value: 1000, label: "1000kg" },
	{ value: 1500, label: "1500kg" },
	{ value: 2000, label: "2000kg" },
	{ value: 2500, label: "2500kg" },
	{ value: 3000, label: "3000kg" }
];
export const minCargoWeightRange: IOption[] = [{ value: "", label: "0kg" }, ...cargoWeightRange];
export const maxCargoWeightRange: IOption[] = [...cargoWeightRange, { value: "", label: "Max" }];

const cargoCapacityRange: IOption[] = [
	{ value: 2.5, label: "2.5m³" },
	{ value: 5.0, label: "5.0m³" },
	{ value: 7.5, label: "7.5m³" },
	{ value: 10.0, label: "10.0m³" },
	{ value: 12.5, label: "12.5m³" },
	{ value: 15.0, label: "15.0m³" },
	{ value: 17.5, label: "17.5m³" }
];
export const minCargoCapacityRange: IOption[] = [{ value: "", label: "0m³" }, ...cargoCapacityRange];
export const maxCargoCapacityRange: IOption[] = [...cargoCapacityRange, { value: "", label: "Max" }];

const towingCapacityRange: IOption[] = [
	{ value: 250, label: "250kg" },
	{ value: 500, label: "500kg" },
	{ value: 1000, label: "1000kg" },
	{ value: 1500, label: "1500kg" },
	{ value: 2000, label: "2000kg" },
	{ value: 2500, label: "2500kg" },
	{ value: 3000, label: "3000kg" },
	{ value: 3500, label: "3500kg" },
	{ value: 4000, label: "4000kg" },
	{ value: 4500, label: "4500kg" }
];
export const minTowingCapacityRange: IOption[] = [{ value: "", label: "0kg" }, ...towingCapacityRange];
export const maxTowingCapacityRange: IOption[] = [...towingCapacityRange, { value: "", label: "Max" }];

// Filters for private Vehicles
export const filters: iFilters = {
	order_by: sortOptions[0],
	body_type: [],
	fuel_type: null,
	price_min: null,
	price_max: null,
	range_min: null,
	range_max: null
};

// Finance Filters
export const financeMakes: IOption[] = [
	{ label: "BYD", value: 42 },
	{ label: "Hyundai", value: 8 },
	{ label: "Jaguar", value: 10 },
	{ label: "Kia", value: 11 },
	{ label: "Land Rover", value: 13 },
	{ label: "MG", value: 17 },
	{ label: "Mercedes-Benz", value: 16 },
	{ label: "ORA", value: 40 },
	{ label: "smart", value: 46 },
	{ label: "Subaru", value: 39 },
	{ label: "Suzuki", value: 37 },
	{ label: "Volvo", value: 31 },
	{ label: "Ford", value: 6 }
];

export const financeSearchMakes: IOption[] = [{ label: "All", value: "all" }, ...financeMakes];

export const financeSortOptions: IOption[] = [
	{ value: "most-recent", label: "Most Recent" },
	// { value: "most-viewed", label: "Most Viewed" },
	{ value: "a-z", label: "Alphabetical" },
	{ value: "", label: "Price: Low to High" },
	{ value: "price-high-low", label: "Price: High to Low" },
	{ value: "range-low-high", label: "Range: Low to High" },
	{ value: "range-high-low", label: "Range: High to Low" }
];
export const financeFilters: iFilters = {
	order_by: financeSortOptions[2],
	body_type: [],
	fuel_type: null,
	finance_price_min: null,
	finance_price_max: null,
	range_min: null,
	range_max: null
};

// Filters for Commercial Vehicles
export const commercialFilters: iFilters = {
	...filters,
	cargo_weight_min: null,
	cargo_weight_max: null,
	cargo_capacity_min: null,
	cargo_capacity_max: null,
	towing_capacity_min: null,
	towing_capacity_max: null
};

// Sweep Filters
export const usedSortOptions: IOption[] = [
	{ label: "Default", value: "" },
	{ label: "Price: Low to High", value: "price" },
	{ label: "Price: High to Low", value: "-price" },
	{ label: "Year: New to Old", value: "-year" },
	{ label: "Year: Old to New", value: "year" }
];

// fuels substitutes from Sweep's backend
export const usedFuelTypeOptions: IOption[] = [
	{ value: "3,6,10,12,14,26,27,28", label: "" }, // Both BEV and PHEV
	{ value: "3", label: "BEV" },
	{ value: "6,10,12,14,26,27,28", label: "PHEV" }
];

const mileages: IOption[] = [
	{ label: "10,000 km", value: 10000 },
	{ label: "20,000 km", value: 20000 },
	{ label: "30,000 km", value: 30000 },
	{ label: "40,000 km", value: 40000 },
	{ label: "50,000 km", value: 50000 },
	{ label: "60,000 km", value: 60000 },
	{ label: "70,000 km", value: 70000 },
	{ label: "80,000 km", value: 80000 },
	{ label: "90,000 km", value: 90000 },
	{ label: "100,000 km", value: 100000 },
	{ label: "125,000 km", value: 125000 },
	{ label: "150,000 km", value: 150000 },
	{ label: "200,000 km", value: 200000 }
];
export const minMileagesRange: IOption[] = [{ value: "", label: "0 km" }, ...mileages];
export const maxMileagesRange: IOption[] = [...mileages, { value: "", label: "Max" }];

export const CURRENT_YEAR = new Date().getFullYear();
const years: IOption[] = [];
for (let i = 2001; i < CURRENT_YEAR; ++i) {
	years.unshift({ label: i.toString(), value: i });
}

export const MIN_YEAR_VALUE = 1954;
export const minYearsRange: IOption[] = [...years, { value: "", label: "<2000" }];
export const maxYearsRange: IOption[] = [{ value: "", label: `${CURRENT_YEAR}` }, ...years];

export const usedFilters: iFilters = {
	order_by: usedSortOptions[0],
	fuel_ids: fuelTypeOptions[0],

	manufacturer_ids: [],
	model_ids: [],
	body_ids: [],
	colors: [],
	location_states: [],

	min_price: null,
	max_price: null,
	min_year: null,
	max_year: null,
	min_mileage: null,
	max_mileage: null
};

export const usedMakesModels: iFilters = {
	manufacturer_ids: [],
	model_ids: []
};

// Leasing Filters
const pricePerMonthRange: IOption[] = [
	{ value: 100, label: "€100" },
	{ value: 200, label: "€200" },
	{ value: 300, label: "€300" },
	{ value: 400, label: "€400" },
	{ value: 500, label: "€500" },
	{ value: 600, label: "€600" },
	{ value: 700, label: "€700" },
	{ value: 800, label: "€800" },
	{ value: 900, label: "€900" },
	{ value: 1000, label: "€1,000" },
	{ value: 1250, label: "€1,250" },
	{ value: 1500, label: "€1,500" },
	{ value: 1750, label: "€1,750" },
	{ value: 2000, label: "€2,000" },
	{ value: 2500, label: "€2,500" },
	{ value: 3000, label: "€3,000" },
	{ value: 3500, label: "€3,500" },
	{ value: 4000, label: "€4,000" },
	{ value: 4500, label: "€4,500" },
	{ value: 5000, label: "€5,000" }
];
export const minPricePerMonthRange: IOption[] = [{ value: "", label: "€0" }, ...pricePerMonthRange];
export const maxPricePerMonthRange: IOption[] = [...pricePerMonthRange, { value: "", label: "Max" }];