import axios from "axios";

export default axios.create({
	baseURL: process.env.NEXT_PUBLIC_NEVO_BASE_API, //"http://localhost:8000",
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Expose-Headers": "Access-Control-*",
		"Access-Control-Allow-Headers": "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept",
		"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
		"Access-Control-Allow-Origin": "*"
	}
});
