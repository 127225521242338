/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useState } from 'react'

import ArrowUpIcon from '../../public/assets/icons/up-arrow.svg'
import ArrowUpIconBoi from '../../public/assets/nevo-boi/chevron-up.svg'
import styles from './styles.module.scss'
import { useIsBoiWebsite } from "../Context/WebsiteType";

const ScrollUp = () => {
  const [display, setDisplay] = useState(false)
  const isBoiWebsite = useIsBoiWebsite();

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => { window.removeEventListener('scroll', onScroll) }
  })

  const onScroll = () => {
    if (window.scrollY > window.innerHeight * 1.3) {
      setDisplay(true)
    }
    else setDisplay(false);
  }

  const scrollUpClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  if (display) {
    return (
      <div className={styles.scrollUp} onClick={scrollUpClick}>
        <img src={isBoiWebsite ? ArrowUpIconBoi.src : ArrowUpIcon.src} alt="arror up"/>
      </div>
    )
  }
  return <></>
};

export default ScrollUp;