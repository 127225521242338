
const endpoints = {
  // Nevo
  ev_station: "/ev_station",
  
  page: "/cms/pages",
  section: "/cms/section",
  component: "/cms/components",
  post: "/cms/blogs",
  blog: '/cms/blogs',
  video: "/cms/videos",
  partner: "/cms/partners",
  statistics: "/cms/statistics",
  hotels: "/cms/hotels",
  nevo_news: "/article",
  form: '/form',
  tags: '/cms/tags',

  manufacturer: "/manufacturer",
  vehicle: "/vehicle",
  dealership: "/cms/dealerships",

  analytics: "/analytics",
  analyticsUsers: `/analytics/users`,

  // sweep
  sweep_feed: "/feed/web",
  sweep_feed_stats: "/feed-stats/web",
  sweep_manufacturers: "/manufacturers",
  sweep_models: "/models",
  sweep_vehicles: "/vehicles",
  sweep_enquiry: 'enquiries/web',
  used_overrides: '/vehicle/used-evs/overrides'
}

export default endpoints;